<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-tabs
            v-if="getCommunicationTemplates && getCommunicationTemplates.length"
            pills
          >
            <template v-for="(notificationTabKey,index) in Object.keys(notificationsTabsMapping)">
              <b-tab
                :key="index"
                :title="notificationsTabsMapping[notificationTabKey].name"
                :active="notificationsTabsMapping[notificationTabKey].path == `/${tabId}`"
                @click="setDeepLink(notificationsTabsMapping[notificationTabKey].path)"
              >
                <TabsCommunicationTemplate
                  :notifications-tabs-mapping="notificationsTabsMapping"
                  :notification-tab="notificationTabKey"
                />
              </b-tab>
            </template>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TabsCommunicationTemplate from '@/views/pages/communication/TabsCommunicationTemplate.vue'

export default {
  components: {
    TabsCommunicationTemplate,
    BCol,
    BRow,
    BCard,
    BTabs,
    BTab,
  },
  computed: {
    ...mapGetters({ getCommunicationTemplates: 'auth/communicationTemplates' }),
  },
  props: ['tabId'],
  data() {
    return {
      notificationsTabsMapping: {
        'before-first-visit': {
          name: 'Before 1st Visit',
          path: '/before',
          data: [
            'v-email-after-signup',
            'v-sms-after-signup',
            'v-email-short-before-visit',
            'v-sms-short-before-visit',
            'v-email-long-before-visit',
            'v-sms-long-before-visit',
          ],
        },
        'after-first-visit': {
          name: 'After 1st Visit',
          path: '/after-visit',
          data: [
            'v-email-after-visit',
            'v-sms-after-visit',
            'v-email-short-after-visit',
            'v-sms-short-after-visit',
            'v-email-long-after-visit',
            'v-sms-long-after-visit',
          ],
        },
        'after-first-didnt-attend': {
          name: 'Reschedule 1st Visit',
          path: '/didnt-attend',
          data: [
            'v-email-after-didnt-attend',
            'v-sms-after-didnt-attend',
            'v-email-short-after-didnt-attend',
            'v-sms-short-after-didnt-attend',
            'v-email-long-after-didnt-attend',
            'v-sms-long-after-didnt-attend',
          ],
        },
        admin: {
          name: 'Admin',
          path: '/admin',
          data: [
            'a-email-after-signup',
            'a-sms-after-signup',
          ],
        },
      },
    }
  },
  methods: {
    setDeepLink(tabPath) {
      const communicationPath = '/communication/templates'
      this.$router.push(communicationPath + tabPath)
    },
  },
}
</script>

<style scoped>
  .custom-control {
    min-height: 1.7rem
  }
</style>
