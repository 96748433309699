<template>
  <b-card-text>
    <div
      class="table-responsive"
    >
      <table
        v-if="communicationTemplates && communicationTemplates.length"
        class="table b-table table-hover"
      >
        <tbody>
          <template v-for="item in notificationTabData">
            <template
              v-for="(template, index) in communicationTemplates"
              v-if="template.name.includes(item)"
            >
              <tr>
                <td
                  class="py-1"
                  :class="{ 'border-0' : index == 0}"
                  @click="gotoEditTemplate(template.id, )"
                >
                  {{ template.channel === 'email' ? 'Email' : 'Text' }}
                </td>
                <td
                  class="py-1"
                  :class="{ 'border-0' : index == 0}"
                  @click="gotoEditTemplate(template.id)"
                >
                  {{ template.title }}
                </td>
                <td
                  class="py-1"
                  :class="{ 'border-0' : index == 0}"
                  @click="gotoEditTemplate(template.id)"
                >
                  Sent {{ template.send_time_description }}
                </td>
                <td
                  class="py-1 d-flex align-items-center justify-content-end"
                  :class="{ 'border-0' : index == 0}"
                >
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="gotoEditTemplate(template.id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-form-checkbox
                    :checked="template.is_active"
                    name="check-button"
                    switch
                    inline
                    @change="changeActiveStatus(template.id, $event)"
                  />
                </td>
              </tr>
            </template>

          </template> </tbody>
      </table>
    </div>
  </b-card-text>
</template>
<script>
import {
  BTab, BCardText, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { cloneDeep } from 'lodash'
import store from '@/store'

export default {
  components: {
    BTab,
    BCardText,
    BButton,
    BFormCheckbox,
  },
  props: ['notificationsTabsMapping', 'notificationTab'],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ communicationTemplates: 'auth/communicationTemplates' }),
    notificationTabData() { return this.notificationsTabsMapping[this.notificationTab].data },
  },
  methods: {
    gotoEditTemplate(templateID) {
      const self = this
      self.$router.push(
        { name: 'edit-communication-template', params: { templateId: templateID, prevPath: window.location.pathname } },
      )
    },
    changeActiveStatus(communicationTemplateId, status) {
      AccountService.updateCommunicationTemplate(communicationTemplateId, { is_active: status })
        .then(() => {
          const account = cloneDeep(store.getters['auth/account'])
          account.communication_templates = account.communication_templates.map(template => {
            if (template.id === communicationTemplateId) {
              return { ...template, is_active: status }
            }
            return template
          })
          store.dispatch('auth/setAuthUserAccount', account)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'BellIcon',
              text: 'Status changes successfully',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
